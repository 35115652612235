import BadgeComponent from 'components/Badge/BadgeComponent';
import logo from 'assets/white-logo.png';

function SuccessView() {
  const device = window.localStorage.getItem('device');
  const isIos = device === 'ios';
  window.localStorage.clear();

  setTimeout(() => {
    if (isIos) {
      // Apple Link
      window.location.href = 'https://apps.apple.com/cl/app/fashions-park/id1630221990?itsct=apps_box_badge&amp;itscg=30200';
    } else {
      // Google Play link
      window.location.href = 'https://play.google.com/store/apps/details?id=cl.innova_ti.tfp_app_mobile&hl=es_CL&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1';
    }
  }, 10000);

  return (
    <div className="home">
      <div className="logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="success-modal">
        <div className="details">
          <div className="title">
            ¡Felicitaciones!
          </div>
          <div className="paragraph">
            Hemos registrado correctamente tus datos. Pronto recibirás una notificación Push en tu App, con el código de $5.000 de Dcto. en tu próxima compra, sobre $30.000 en nuestra tienda en línea. Este código es único y no debes compartirlo, límite de cupones es para los primeros 3.000 usuarios.
          </div>
          <div className="paragraph">
            En breve te redirigiremos para descargar nuestra aplicación desde la tienda de tu dispositivo. O también puedes acceder mediante el siguiente botón:
          </div>
        </div>
        <BadgeComponent isIos={isIos} isSuccessPage={true} />
      </div>
    </div>
  );
}

export default SuccessView;
