import logo from 'assets/white-logo.png';
import FormComponent from 'components/Form/FormComponent';
import LegendComponent from 'components/Legend/LegendComponent';
import WelcomeComponent from 'components/Welcome/WelcomeComponent';
import { useLoaderData } from 'react-router-dom';

function HomeView() {
  const { invitation, termConds } = useLoaderData();

  return (
    <div className="home">
      <div className="logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="referrer-modal">
        <WelcomeComponent name={invitation.name} />
        <LegendComponent />
        <FormComponent url={termConds.url} referredId={invitation.referredId} />
      </div>
    </div>
  );
}

export default HomeView;
