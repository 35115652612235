import './WelcomeComponent.css'

function WelcomeComponent(props) {
    return (
        <div className="welcome-section">
            <div className="title">
                Bienvenido
            </div>
            <div className="paragraph">
                <strong>{ props.name }</strong> ha compartido este link contigo para que obtengas los mismos beneficios en nuestra App
            </div>
        </div>
    );
}

export default WelcomeComponent;
