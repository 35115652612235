import './LegendComponent.css'

function LegendComponent() {
    return (
        <div className="legend-section">
            <div className='legend'>
                Accede a un mundo de beneficios
            </div>
            <div className='image'>
                
            </div>
            <div className='paragraph'>
                Sólo debes <strong>
                    ingresar tu RUT
                </strong> y <strong>
                    tipo de dispositivo
                </strong> para registrarte y poder acceder a un mundo de <strong>
                    beneficios
                </strong> con nuestra nueva App Fashion's Park
            </div>
        </div>
    );
}

export default LegendComponent;
