import { useSubmit } from 'react-router-dom';
import './FormComponent.css';
import { useState } from 'react';

function FormComponent(props) {
  const [rut, setRut] = useState("");
  const [device, setDevice] = useState("");
  const [errorRut, setErrorRut] = useState(false);
  const [errorDevice, setErrorDevice] = useState(false);
  const submit = useSubmit();

  const isDevice = /Android|iPhone|iPad/i.test(navigator.userAgent);

  const removeRUTSpecialChars = () => {
    const currentRUT = rut;
    if (!currentRUT) {
      setRut("");
    } else if (currentRUT.trim() === '') {
      setRut(currentRUT);
    } else {
      const newRUT = currentRUT.replace(/[.-]/gi, '');
      setRut(newRUT);
    }
  };

  const formatRUT = () => {
    const currentRUT = rut;
    if (!currentRUT) {
      setRut("");
    } else if (currentRUT.trim() !== '') {
      const newRUT = currentRUT.replace(/[.-]/gi, '');
      let unitsRUT = '', thousandsRUT = '', millionsRUT = '';
      if (newRUT.length > 7)
        millionsRUT = `${newRUT.slice((newRUT.length * -1), -7)}.`;
      if (newRUT.length > 4)
        thousandsRUT = `${newRUT.slice(-7, -4)}.`;
      if (newRUT.length > 1)
        unitsRUT = `${newRUT.slice(-4, -1)}`;
      const formattedRUT = `${millionsRUT}${thousandsRUT}${unitsRUT}-${newRUT.slice(-1)}`;
      setRut(formattedRUT);
    }
  };

  const validateRUT = () => {
    try {
      const currentRUT = rut;
      if (!currentRUT) {
        return false;
      }
      let rutArray = currentRUT.replace(/[.-]/gi, '').split(/||/);
      const numberArray = [2, 3, 4, 5, 6, 7];
      let dv = rutArray.pop();
      let currentCalculation = 0;
      rutArray.reverse();


      rutArray.forEach((element, index) => {
        let currentNumber = Number.parseInt(element);
        let currentIndexCalculation = index % numberArray.length;
        currentCalculation = (currentNumber * numberArray[currentIndexCalculation]) + currentCalculation;
      });

      let resultElevenModule = 11 - (currentCalculation % 11);

      if (resultElevenModule > 0 && resultElevenModule < 10) {
        return (Number.parseInt(dv) === resultElevenModule);
      } else if (resultElevenModule === 10) {
        return (dv.toLowerCase() === 'k');
      } else if (resultElevenModule === 11) {
        return (Number.parseInt(dv) === 0);
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  const handleChange = (event) => {
    const data = event.target.value;
    setRut(data);
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    const rutData = rut;
    let deviceData = device;

    if (isDevice) {
      const isIos = /iPhone|iPad/i.test(navigator.userAgent);
      const isAndroid = /Android/i.test(navigator.userAgent);

      if (isIos) {
        deviceData = 'ios';
      }

      if (isAndroid) {
        deviceData = 'android';
      }
    }

    const resultRUT = validateRUT();
    if (!resultRUT || deviceData === '') {
      setErrorRut(!resultRUT);
      setErrorDevice(deviceData === '');
      return;
    } else {
      setErrorRut(false);
      setErrorDevice(false);
    }

    const formData = {
      rut: rutData,
      device: deviceData,
      referredId: props.referredId
    };

    window.localStorage.setItem('device', deviceData);

    submit(formData, {
      method: 'post',
      encType: "application/json",
    });
  }

  let selectInputDevice = (
    <select name="device" value={device} onChange={(e) => setDevice(e.target.value)} aria-label="Dispositivo">
      <option value="">Seleccione tipo de dispositivo</option>
      <option value="ios">iOS</option>
      <option value="android">Android</option>
    </select>
  );

  if (isDevice) {
    selectInputDevice = undefined;
  }

  return (
    <div className="form-section">
      <form className="form" onSubmit={handleSubmit}>
        <div className="input-group">
          <label htmlFor="rut">Ingrese su RUT</label>
          <input type="text" id="rut" name="rut" placeholder="RUT"
            onFocus={removeRUTSpecialChars} onBlur={formatRUT}
            onChange={handleChange}
            value={rut} />

          {errorRut && (<div className='error'>
            RUT contiene errores</div>)}
        </div>
        <div className="input-group">
          {selectInputDevice}

          {errorDevice && (<div className='error'>
            Dispositivo no seleccionado</div>)}
        </div>
        <div className="input-group btn">
          <input type="submit" value="Continuar" className="active full-button" />
        </div>
      </form>
      <div className="terms-and-cond">
        <p>
          Al presionar "Continuar" estás de acuerdo con nuestros <a href={props.url} target="_blank" rel="noreferrer noopener">
            términos y condiciones
          </a> de evaluación crediticia y utilización de datos.
        </p>
      </div>
    </div>
  );
}

export default FormComponent;
