import services from "services/service";

const toJSON = async (body) => {
    const reader = body.getReader();
    const decoder = new TextDecoder();
    const chunks = [];
  
    async function read() {
      const { done, value } = await reader.read();
  
      // all chunks have been read?
      if (done) {
        return JSON.parse(chunks.join(''));
      }
  
      const chunk = decoder.decode(value, { stream: true });
      chunks.push(chunk);
      return read(); // read the next chunk
    }
  
    return read();
  }

const actions = {
    createData: async ({request}) => {
        let data = await toJSON(request.body);
        console.log(data);
        /* let formData = new FormData();
        let currentKeys = Object.keys(data);
        currentKeys.forEach((element) => {
            formData.append(element, data[element]);
        }); */
        return await services.postData(data);
    },
}

export default actions;