let urlServices = process.env.REACT_APP_SERVICES_URL ? process.env.REACT_APP_SERVICES_URL : 'https://api.assi.cl/tfp/app/1.0.0';
let apiKey = process.env.REACT_APP_API_KEY ? process.env.REACT_APP_API_KEY : 'key';

/**
 * @method callService
 * @param {String} url 
 * @param {String} method 
 * @param {*} data 
 * @returns 
 */
const callService = (url, method = 'GET', data = undefined) => {
    return new Promise(async (resolve, reject) => {
        await fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Apikey': `${apiKey}`,
            },
            mode: 'cors',
            body: data ? JSON.stringify(data) : data,
        }).then(
            (res) => {
                return resolve(res);
            },
            (rej) => {
                return reject(rej);
            }
        ).catch((err) => {
            return reject(err);
        });
    });
};

/**
 * @Object services
 * Contains method to call services
 */
const services = {
    /**
     * @method getData
     * @returns Promise
     */
    getData: async () => {
        const url = `${urlServices}/`;
        return await callService(url);
    },
    /**
    * @method getDataDetail
     * @param {string} dataToken
    * @returns Promise
    */
    getDataDetail: async  (dataToken) => {
       const url = `${urlServices}/${dataToken}`;
       return await callService(url);
   },
   /**
    * @method postData
    * @param {*} data 
    * @returns Promise
    */
   postData: async (data = undefined) => {
       const url = `${urlServices}/`;
       const method = 'POST';
       return await callService(url, method, data);
   },
   /**
    * @method putDataDetail
    * @param {number} dataId
    * @param {*} data 
    * @returns Promise
    */
   putDataDetail: async (dataId, data = undefined) => {
       const url = `${urlServices}/${dataId}`;
       const method = 'PUT';
       return await callService(url, method, data);
   },
   
   /**
    * @method getTermConds
    * @returns Promise
    */
   getTermConds: async () => {
       const url = `${urlServices}/term-conds`;
       return await callService(url);
   },
};

export default services;