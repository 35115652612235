import './BadgeComponent.css';

function BadgeComponent(props) {
    const iosButton = (<div className='badge'>
        <a href="https://apps.apple.com/cl/app/fashions-park/id1630221990?itsct=apps_box_badge&amp;itscg=30200">
            <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/es-mx?size=250x83&amp;releaseDate=1656460800" alt="Descárgalo en el App Store" />
        </a>
    </div>);
    const androidButton = (<div className='badge'>
        <a href='https://play.google.com/store/apps/details?id=cl.innova_ti.tfp_app_mobile&hl=es_CL&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
            <img alt='Disponible en Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/es-419_badge_web_generic.png' />
        </a>
    </div>);

    let rendered = (<div className='badge-component'>{iosButton} {androidButton}</div>)

    if (props.isSuccessPage)
        rendered = (<div className='badge-component'>{props.isIos ? (iosButton) : (androidButton)}</div>);

    return rendered;
}

export default BadgeComponent;