import services from "services/service";


const referrerLoader = {
    getData: async () => {
        return await services.getData();
    },
    getDataDetail: async ({ params }) => {
        return await services.getDataDetail(encodeURIComponent(params['*']));
    },
    getTermConds: async () => {
        return await services.getTermConds();
    },
}

export default referrerLoader;