import BadgeComponent from 'components/Badge/BadgeComponent';
import logo from 'assets/white-logo.png';

function NotFoundView() {
  return (
    <div className="home">
      <div className="logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="bad-modal">
        <div className="details">
          <div className="title">
            ¡Oh, no encontramos la invitación!
          </div>
          <div className="paragraph">
            Al parecer no hemos encontrado quién te invitó, puedes intentar nuevamente más tarde.
          </div>
          <div className="paragraph">
            También puedes descargar nuestra aplicación desde la tienda de tu dispositivo.
          </div>
        </div>
        <BadgeComponent />
      </div>
    </div>
  );
}

export default NotFoundView;
