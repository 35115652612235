import BadgeComponent from 'components/Badge/BadgeComponent';
import logo from 'assets/white-logo.png';

function BadView() {
  return (
    <div className="home">
      <div className="logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="bad-modal">
        <div className="details">
          <div className="title">
            ¡Oh, no! ¡Ocurrió un problema!
          </div>
          <div className="paragraph">
            Lamentamos no poder procesar tu registro, estamos haciendo lo posible por solucionarlo. <br />
            Puedes intentarlo nuevamente más tarde.
          </div>
          <div className="paragraph">
            También puedes descargar nuestra aplicación desde la tienda de tu dispositivo.
          </div>
        </div>
        <BadgeComponent />
      </div>
    </div>
  );
}

export default BadView;
