import { createBrowserRouter, json, redirect } from "react-router-dom";
import App from 'App';
import BadView from "views/BadView";
import HomeView from "views/HomeView";
import NotFoundView from "views/NotFoundView";
import SuccessView from "views/SuccessView";
import NotAllowedView from "views/NotAllowedView";
import RegisteredView from "views/RegisteredView";
import loaders from "./loaders/loader";
import actions from "./actions/action";


const indexRouter = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    action: async (req) => {
      try {
        const result = await actions.createData(req);
        console.log(result);
        if (result?.status === 201) {
          const responseData = await result.json();
          if (responseData.id === 0) {
            return redirect('/registered');
          }
          return redirect('/completed');
        } else if (result?.status === 200) {
          return redirect('/success');
        }
        return redirect('/error');

      } catch (err) {
        return redirect('/error');
      }
    },
    children: [
      {
        path: '',
        loader: async () => { return redirect('/not-found'); },
      },
      {
        path: '*',
        name: "Invitacion",
        element: <HomeView />,
        loader: async (req) => {
          try {
            const [invitationRes, termConds] = await Promise.all([
              loaders.referrer.getDataDetail(req),
              loaders.referrer.getTermConds()
            ]);
            if (invitationRes === undefined || invitationRes?.status !== 200) {
              return redirect('/not-found');
            }
            const invitation = await invitationRes?.json();
            return json({ invitation, termConds });
          } catch (err) {
            return redirect('/not-found');
          }
        },
      },
      {
        path: 'not-found',
        name: "No encontrado",
        element: <NotFoundView />
      },
      {
        path: 'error',
        name: "Error",
        element: <BadView />
      },
      {
        path: 'success',
        name: "Exito",
        element: <SuccessView />
      },
      {
        path: 'registered',
        name: "Invitacion aceptada",
        element: <RegisteredView />
      },
      {
        path: 'completed',
        name: "Usuario registrado",
        element: <NotAllowedView />
      },
    ]
  }
]);

export default indexRouter;